@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,100&family=Sintony:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* .swiper-container {
    width: 480px;
  }
  
  @media screen and (min-width: 640px) {
    .swiper-container {
      width: 640px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  } */

  .service-image{
    background-image: url("https://images.unsplash.com/photo-1589939705384-5185137a7f0f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80");
    background-size: cover;
  }
  .service-hero{
    background-image:url("https://images.pexels.com/photos/834892/pexels-photo-834892.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
    background-size: cover;
    /* background-position-y: -200px; */
  }
  
  .services-banner{
    background-image:url("https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1475&q=80");
    background-size: cover;
    /* background-position-y: -200px; */
    background-size: cover;
  }

  body{
    font-family:"Poppins";
    background-color: #F7F9FA;
  }